import React from 'react'

interface SurpriseGiftProps {
  className?: string
}

const SurpriseGift: React.FC<SurpriseGiftProps> = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="64"
    viewBox="0 0 64 64"
    width="64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.7446 20.9341L31.6046 29.018L53.3021 21.1478L32.2394 13.4912L10.7446 20.9341Z"
      fill="#F9F6F3"
    />
    <path
      d="M31.6046 29.9082C31.4988 29.9082 31.393 29.8904 31.2872 29.8459L10.4273 21.762C10.0835 21.6285 9.85422 21.2902 9.86304 20.9162C9.86304 20.5423 10.1099 20.2129 10.4626 20.0883L31.9485 12.6454C32.1336 12.5831 32.3452 12.5831 32.5304 12.6454L53.5931 20.3019C53.9457 20.4266 54.175 20.7649 54.175 21.1388C54.175 21.5127 53.9457 21.851 53.5931 21.9757L31.8956 29.8459C31.7986 29.8815 31.7016 29.8993 31.5958 29.8993L31.6046 29.9082ZM13.3279 20.9786L31.6134 28.0653L50.6924 21.1388L32.2218 14.426L13.3191 20.9786H13.3279Z"
      fill="#6E3264"
    />
    <path
      d="M11.3794 21.1475C10.9562 21.1475 10.7446 21.1475 10.7446 21.5748V51.7826C10.7446 51.7826 10.7446 52.2099 11.1678 52.2099L31.6046 59.2344C32.0278 59.2344 32.2394 59.0919 32.2394 58.807V29.2314C32.2394 29.2314 32.2394 28.804 31.8162 28.804L11.3794 21.1475Z"
      fill="url(#paint0_linear_202_2252)"
    />
    <path
      d="M31.6049 60.1164C31.5079 60.1164 31.4109 60.0986 31.3227 60.0719L10.9918 53.092C10.2865 53.0029 9.86328 52.3886 9.86328 51.7921V21.5755C9.86328 20.703 10.3746 20.2578 11.3797 20.2578C11.4855 20.2578 11.5913 20.2756 11.6883 20.3112L32.0104 27.9233C32.7069 28.0212 33.1213 28.6355 33.1213 29.2231V58.7899C33.1213 60.1075 32.1779 60.1075 31.6049 60.1075V60.1164ZM11.6266 51.4271L31.3492 58.2023V29.5792L11.6266 22.1898V51.4271Z"
      fill="#6E3264"
    />
    <path
      d="M52.685 21.1475C53.0994 21.1475 53.3022 21.1475 53.3022 21.5748V51.7826C53.3022 51.7826 53.3022 52.2099 52.8878 52.2099L32.8742 59.2344C32.0455 59.2344 32.2571 59.2344 32.2571 58.807V29.2314C32.2571 29.2314 32.2571 28.804 32.6715 28.804L52.685 21.1475Z"
      fill="url(#paint1_linear_202_2252)"
    />
    <path
      d="M32.8744 60.1163C32.2661 60.1163 31.9046 60.1163 31.596 59.7602C31.3051 59.4308 31.3491 59.0479 31.3668 58.8966C31.3668 58.8699 31.3668 58.8343 31.3668 58.7987V29.2319C31.3668 28.6354 31.7723 28.03 32.46 27.9321L52.359 20.32C52.4559 20.2844 52.5617 20.2666 52.6675 20.2666C53.6638 20.2666 54.1663 20.7118 54.1663 21.5842V51.792C54.1663 52.3885 53.752 53.0028 53.0555 53.0919L33.1477 60.0718C33.0507 60.1074 32.9538 60.1252 32.8568 60.1252L32.8744 60.1163ZM33.1389 29.5791V58.1933L52.4207 51.427V22.1985L33.1389 29.5791Z"
      fill="#6E3264"
    />
    <path
      d="M9.66943 20.729L31.5785 29.2225L54.3781 20.9516L32.2486 12.9033L9.66943 20.729Z"
      fill="#F9F6F3"
    />
    <path
      d="M31.5781 30.1127C31.4723 30.1127 31.3665 30.0949 31.2607 30.0504L9.35161 21.557C9.00777 21.4234 8.77854 21.0851 8.78736 20.7112C8.78736 20.3372 9.03422 20.0078 9.38688 19.8832L31.9572 12.0575C32.1424 11.9952 32.354 11.9952 32.5391 12.0575L54.6686 20.1058C55.0213 20.2304 55.2505 20.5687 55.2505 20.9426C55.2505 21.3166 55.0213 21.6549 54.6686 21.7795L31.8691 30.0504C31.7721 30.086 31.6751 30.1038 31.5693 30.1038L31.5781 30.1127ZM12.2523 20.7735L31.5957 28.2698L51.7768 20.9515L32.2393 13.847L12.2523 20.7735Z"
      fill="#6E3264"
    />
    <path
      d="M10.3307 20.9521C9.88985 20.9521 9.66943 20.9521 9.66943 21.3973V29.223C9.66943 29.223 9.66943 29.6682 10.1103 29.6682L31.5785 37.0398C32.0194 37.0398 32.2398 36.8914 32.2398 36.5947V29.4456C32.2398 29.4456 32.2398 29.0004 31.7989 29.0004L10.3307 20.9521Z"
      fill="#F9F6F3"
    />
    <path
      d="M31.5784 37.9387C31.4814 37.9387 31.3844 37.9209 31.2962 37.8942L9.93375 30.5492C9.18434 30.4602 8.7876 29.8281 8.7876 29.2227V21.397C8.7876 20.5067 9.30777 20.0615 10.3305 20.0615C10.4363 20.0615 10.5421 20.0793 10.6391 20.1149L31.9927 28.1187C32.7333 28.2077 33.1301 28.8488 33.1301 29.4453V36.5943C33.1301 37.9298 32.2043 37.9298 31.5872 37.9298L31.5784 37.9387ZM10.5509 28.8844L31.3579 36.0335V29.7925L10.5509 21.9846V28.8844Z"
      fill="#6E3264"
    />
    <path
      d="M53.7255 20.9521C54.1575 20.9521 54.3779 20.9521 54.3779 21.3973V29.223C54.3779 29.223 54.3779 29.6682 53.9459 29.6682L32.9185 37.0398C32.0544 37.0398 32.266 37.0398 32.266 36.5947V29.4456C32.266 29.4456 32.266 29.0004 32.698 29.0004L53.7255 20.9521Z"
      fill="#F9F6F3"
    />
    <path
      d="M32.9185 37.9386C32.2925 37.9386 31.9222 37.9386 31.6048 37.5825C31.3138 37.2441 31.3579 36.8613 31.3756 36.71C31.3756 36.6833 31.3844 36.6476 31.3844 36.6031V29.454C31.3844 28.8575 31.7723 28.2254 32.5041 28.1275L53.4081 20.1237C53.5051 20.0881 53.6109 20.0703 53.7167 20.0703C54.7306 20.0703 55.2508 20.5155 55.2508 21.4058V29.2315C55.2508 29.8369 54.854 30.469 54.1134 30.558L33.2006 37.8941C33.1036 37.9297 33.0066 37.9475 32.9096 37.9475L32.9185 37.9386ZM33.1477 29.7835V36.0244L53.4963 28.8843V21.9934L33.1477 29.7835Z"
      fill="#6E3264"
    />
    <path
      d="M44.8822 16.7228L21.4919 24.958L21.4302 33.5405L15.7876 31.6085L15.8052 22.8302L39.4247 15.6455L44.8822 16.7228Z"
      fill="#56BB6C"
    />
    <path
      d="M21.4305 34.4307C21.3335 34.4307 21.2365 34.4129 21.1484 34.3862L15.5058 32.4543C15.1443 32.3296 14.9062 31.9913 14.9062 31.6085L14.9239 22.8301C14.9239 22.4384 15.1796 22.0912 15.5499 21.9755L39.1694 14.7908C39.3104 14.7462 39.4515 14.7373 39.5926 14.773L45.05 15.8502C45.4379 15.9303 45.7289 16.2597 45.7641 16.6604C45.7906 17.061 45.5525 17.4349 45.1734 17.5685L22.365 25.599L22.3121 33.5493C22.3121 33.8342 22.1711 34.1013 21.9418 34.2705C21.792 34.3773 21.6068 34.4396 21.4305 34.4396V34.4307ZM16.6696 30.9764L20.5577 32.3118L20.6105 24.9669C20.6105 24.5929 20.8486 24.2546 21.2013 24.13L41.5146 16.9809L39.4691 16.5803L16.6784 23.5068L16.6607 30.9853L16.6696 30.9764Z"
      fill="#6E3264"
    />
    <path
      d="M22.4964 33.9144L22.3201 56.0382L16.8018 54.1419L16.8546 31.9824L22.4964 33.9144Z"
      fill="#56BB6C"
    />
    <path
      d="M22.3207 56.9378C22.2237 56.9378 22.1268 56.92 22.0386 56.8932L16.5194 54.9969C16.158 54.8723 15.9199 54.534 15.9199 54.1511L15.9728 31.9916C15.9728 31.7067 16.1139 31.4397 16.3431 31.2705C16.5723 31.1013 16.8721 31.0568 17.1366 31.1548L22.7792 33.0867C23.1407 33.2113 23.3787 33.5497 23.3787 33.9414L23.2024 56.0653C23.2024 56.3502 23.0613 56.6173 22.8321 56.7864C22.6822 56.8932 22.5059 56.9467 22.3207 56.9467V56.9378ZM17.6832 53.5101L21.4479 54.801L21.6066 34.5557L17.7273 33.2292L17.6832 53.5101Z"
      fill="#6E3264"
    />
    <path
      d="M47.7391 31.8222L42.0965 33.8076L42.026 25.0559L18.9619 16.7228L24.4282 15.6455L47.7215 22.9281L47.7391 31.8222Z"
      fill="#56BB6C"
    />
    <path
      d="M42.0964 34.6977C41.92 34.6977 41.7349 34.6443 41.585 34.5374C41.3558 34.3683 41.2147 34.1012 41.2147 33.8163L41.153 25.6879L18.662 17.5684C18.2917 17.4348 18.0537 17.0609 18.0801 16.6603C18.1066 16.2596 18.3975 15.9302 18.7943 15.8501L24.2605 14.7728C24.4016 14.7461 24.5515 14.755 24.6925 14.7995L47.9858 22.0733C48.3561 22.189 48.603 22.5362 48.603 22.9191L48.6206 31.8131C48.6206 32.1959 48.3826 32.5343 48.0299 32.6589L42.3873 34.6443C42.2903 34.6799 42.1934 34.6977 42.0964 34.6977ZM22.2856 16.9808L42.3256 24.2189C42.6695 24.3435 42.9075 24.6729 42.9075 25.0469L42.9692 32.5521L46.8573 31.181L46.8397 23.5779L24.3751 16.5623L22.2768 16.9719L22.2856 16.9808Z"
      fill="#6E3264"
    />
    <path
      d="M47.0418 54.1424L41.5234 56.0387L41.356 34.084L46.9977 32.0986V32.2678L47.0418 54.1424Z"
      fill="#56BB6C"
    />
    <path
      d="M41.5497 57.2117C41.3645 57.2117 41.1882 57.1583 41.0383 57.0514C40.8091 56.8823 40.668 56.6152 40.668 56.3303L40.5005 34.3756C40.5005 33.9927 40.7385 33.6544 41.0912 33.5298L46.7338 31.5444C47.0071 31.4465 47.298 31.491 47.5361 31.6601C47.7653 31.8293 47.9064 32.0964 47.9064 32.3902L47.9505 54.4339C47.9505 54.8168 47.7124 55.1551 47.3509 55.2797L41.8318 57.1761C41.7348 57.2117 41.6466 57.2206 41.5497 57.2206V57.2117ZM42.2726 34.9899L42.4225 55.075L46.1872 53.784L46.1431 33.6277L42.2638 34.9899H42.2726Z"
      fill="#6E3264"
    />
    <path
      d="M31.6841 16.3046C32.6098 17.5065 35.0608 19.0022 40.483 21.0143C45.4643 22.8661 50.1988 21.9936 51.8828 19.02C52.1649 18.5482 52.35 18.0229 52.4558 17.4353C52.6586 16.3046 52.738 8.34538 52.3236 7.45508C50.8953 8.23854 46.7692 11.8086 41.1442 14.5596C35.2636 17.4442 33.3857 17.2127 31.6841 16.2957V16.3046Z"
      fill="url(#paint2_linear_202_2252)"
    />
    <path
      d="M45.9228 23.3108C44.23 23.3108 42.3874 22.9725 40.5095 22.2781C35.4047 20.3817 32.4864 18.797 31.3138 17.2835C31.0581 16.9541 31.067 16.4822 31.3315 16.1617C31.5959 15.8412 32.0544 15.7522 32.4159 15.948C33.7472 16.6603 35.3165 17.0164 41.0825 14.1942C44.9177 12.3156 48.03 10.0543 50.093 8.55859C51.0452 7.86415 51.7329 7.36559 52.2266 7.1074C52.4382 6.99166 52.6939 6.96495 52.9232 7.04508C53.1524 7.12521 53.3463 7.29436 53.4433 7.51694C54.0252 8.77226 53.8048 17.1143 53.6461 18.0224C53.5227 18.708 53.2934 19.3401 52.9584 19.901C51.7065 22.1178 49.0791 23.3108 45.914 23.3108H45.9228ZM35.4223 18.1471C36.8418 18.9038 38.7462 19.7229 41.1266 20.6043C45.6319 22.2781 49.9784 21.6103 51.4508 19.0018C51.68 18.61 51.8387 18.1827 51.9269 17.7019C52.0944 16.7493 52.1297 11.7637 51.9886 9.39546C51.7329 9.58243 51.4508 9.77829 51.1422 10.0009C49.0351 11.5322 45.8435 13.8469 41.8672 15.7878C38.9842 17.2034 36.974 17.9156 35.4223 18.1382V18.1471Z"
      fill="#6E3264"
    />
    <path
      d="M32.0015 17.4084C33.2005 16.3846 33.8353 18.2631 36.0835 17.5419C37.2649 17.1591 41.0472 15.2984 42.4138 14.5238C45.2439 12.9213 48.7176 11.6749 50.8689 15.0046C51.5301 16.0195 51.7064 17.2749 51.486 18.4768C51.2303 19.8567 50.4368 21.3346 50.2429 21.3791C53.7342 20.5422 56.8994 15.6278 55.9736 11.666C54.4572 5.21133 45.9404 2.18432 40.5711 7.55281C36.877 11.2475 33.5973 15.3162 32.0103 17.3995L32.0015 17.4084Z"
      fill="#56BB6C"
    />
    <path
      d="M50.2431 22.2695C49.8464 22.2695 49.4849 21.9936 49.3879 21.584C49.3086 21.2279 49.4408 20.8807 49.7141 20.667C49.9169 20.3821 50.4459 19.3227 50.631 18.3077C50.825 17.275 50.6487 16.2689 50.1373 15.4855C48.7267 13.2954 46.4785 13.2419 42.8549 15.2896C41.5059 16.0553 37.6531 17.9605 36.3571 18.3789C34.8935 18.8508 33.9149 18.4324 33.2713 18.1475C32.7511 17.9249 32.7511 17.9249 32.5748 18.0673C32.2309 18.3611 31.7196 18.3433 31.3934 18.0317C31.0671 17.7201 31.0319 17.2038 31.3052 16.8476C32.275 15.5745 35.7752 11.0874 39.9454 6.90302C42.6168 4.23213 46.3286 3.2439 49.8728 4.27664C53.3554 5.28268 56.0268 8.02479 56.8203 11.4435C57.8165 15.6992 54.5368 21.2457 50.4371 22.2339C50.3665 22.2517 50.296 22.2606 50.2343 22.2606L50.2431 22.2695ZM47.2279 12.0044C48.7884 12.0044 50.3754 12.6187 51.6009 14.524C52.3591 15.6902 52.6236 17.1592 52.3503 18.646C52.3326 18.7618 52.2621 19.0467 52.1563 19.4117C54.2106 17.7379 55.7535 14.613 55.1098 11.8798C54.4574 9.09315 52.2621 6.8407 49.3879 6.01272C46.4167 5.15804 43.4279 5.9504 41.1885 8.19395C38.1116 11.2744 35.4049 14.524 33.8444 16.4826C33.8796 16.5004 33.9149 16.5093 33.9502 16.5271C34.4968 16.7586 34.9729 16.9634 35.8104 16.6963C36.9037 16.3491 40.6066 14.5329 41.9732 13.7494C42.9342 13.2063 45.0414 12.0044 47.219 12.0044H47.2279Z"
      fill="#6E3264"
    />
    <path
      d="M32.0013 21.7708C34.3872 21.7708 36.3214 19.8177 36.3214 17.4084C36.3214 14.999 34.3872 13.0459 32.0013 13.0459C29.6153 13.0459 27.6812 14.999 27.6812 17.4084C27.6812 19.8177 29.6153 21.7708 32.0013 21.7708Z"
      fill="#56BB6C"
    />
    <path
      d="M32.0015 22.6613C29.6475 22.6613 27.5051 21.032 26.9408 18.6193C26.2796 15.7971 28.0076 12.9659 30.7936 12.2893C32.1426 11.9599 33.5444 12.1914 34.7258 12.9214C35.9072 13.6604 36.736 14.8088 37.0622 16.1799C37.3884 17.542 37.1592 18.9576 36.4362 20.1506C35.7044 21.3436 34.5671 22.1805 33.2094 22.5099C32.8038 22.6078 32.3982 22.6524 32.0015 22.6524V22.6613ZM28.66 18.2098C29.1009 20.0705 30.9611 21.219 32.8038 20.7827C33.6943 20.5691 34.4525 20.0171 34.9374 19.2247C35.4223 18.4323 35.5634 17.5064 35.3518 16.5983C35.1402 15.6902 34.5936 14.9335 33.8089 14.4438C33.0242 13.9541 32.0985 13.8028 31.208 14.0254C29.3654 14.4705 28.2192 16.349 28.66 18.2098Z"
      fill="#6E3264"
    />
    <path
      d="M31.9927 16.7412C31.0669 17.9431 28.6159 19.4388 23.1938 21.4508C18.2124 23.3026 13.4779 22.4302 11.794 19.4566C11.5119 18.9847 11.3267 18.4594 11.2209 17.8718C11.0181 16.7412 10.9388 8.7819 11.3532 7.8916C12.7814 8.67506 16.9076 12.2452 22.5325 14.9962C28.4132 17.8807 30.2911 17.6493 31.9927 16.7323V16.7412Z"
      fill="url(#paint3_linear_202_2252)"
    />
    <path
      d="M18.0887 23.3109C14.9147 23.3109 12.2962 22.1179 11.0266 19.8833C10.7004 19.3313 10.4712 18.6992 10.3478 18.0137C10.1891 17.1056 9.96865 8.75456 10.5505 7.50815C10.6563 7.28557 10.8415 7.11642 11.0707 7.03629C11.2999 6.95616 11.5556 6.98287 11.7672 7.09861C12.2521 7.3657 12.9398 7.86427 13.9008 8.5587C15.9639 10.0544 19.0761 12.3068 22.9113 14.1943C28.6773 17.0165 30.2467 16.6604 31.578 15.9482C31.9483 15.7523 32.3979 15.8413 32.6624 16.1618C32.9269 16.4823 32.9357 16.9542 32.68 17.2836C31.5163 18.7971 28.5892 20.3907 23.4844 22.2782C21.6065 22.9726 19.7638 23.3109 18.0799 23.3109H18.0887ZM12.0229 9.39558C11.8818 11.7371 11.9171 16.7494 12.0934 17.711C12.1728 18.1828 12.3315 18.6191 12.5607 18.993C14.0419 21.6105 18.3884 22.2871 22.8937 20.6133C25.2742 19.7319 27.1785 18.9129 28.598 18.1561C27.0463 17.9246 25.0361 17.2213 22.1531 15.8057C18.1857 13.856 14.9941 11.5501 12.8869 10.0188C12.5783 9.79621 12.2962 9.59144 12.0317 9.40448L12.0229 9.39558Z"
      fill="#6E3264"
    />
    <path
      d="M32.0014 17.4087C30.8024 16.3848 30.1676 18.2634 27.9194 17.5422C26.738 17.1594 22.9557 15.2987 21.5891 14.5241C18.759 12.9216 15.2853 11.6752 13.134 15.0049C12.4728 16.0198 12.2965 17.2751 12.5169 18.477C12.7726 19.857 13.566 21.3349 13.76 21.3794C10.2775 20.5425 7.10353 15.6281 8.03808 11.6663C9.55452 5.2027 18.0625 2.17569 23.4406 7.55309C27.1347 11.2478 30.4145 15.3165 32.0014 17.3998V17.4087Z"
      fill="#56BB6C"
    />
    <path
      d="M13.7688 22.2694C13.6983 22.2694 13.6365 22.2694 13.566 22.2427C9.4575 21.2634 6.17775 15.7168 7.18283 11.4612C7.98514 8.04247 10.6477 5.30035 14.1303 4.29432C17.6833 3.27048 21.3863 4.2498 24.0577 6.9207C28.2279 11.0962 31.7281 15.5833 32.6979 16.8653C32.9712 17.2214 32.9359 17.7378 32.6097 18.0494C32.2835 18.361 31.7722 18.3788 31.4283 18.085C31.2608 17.9426 31.252 17.9337 30.7318 18.1651C30.0882 18.4411 29.1096 18.8685 27.646 18.3966C26.35 17.9782 22.4972 16.0729 21.1482 15.3073C17.5246 13.2507 15.2764 13.313 13.8658 15.5032C13.3544 16.2866 13.1869 17.2927 13.372 18.3254C13.5572 19.3314 14.0862 20.3998 14.289 20.6847C14.5535 20.8895 14.6945 21.2456 14.6064 21.6017C14.5094 22.0112 14.1479 22.2872 13.7512 22.2872L13.7688 22.2694ZM16.9516 5.66538C16.1757 5.66538 15.391 5.77221 14.6152 6.00369C11.741 6.83167 9.54567 9.08412 8.89324 11.8707C8.24964 14.604 9.79253 17.7289 11.8468 19.4027C11.741 19.0376 11.6705 18.7527 11.6528 18.637C11.3795 17.1502 11.6528 15.6812 12.4022 14.5149C15.3469 9.95661 20.3724 12.8056 22.0211 13.7404C23.3965 14.5238 27.0994 16.34 28.1838 16.6873C29.0302 16.9543 29.4975 16.7585 30.0441 16.5181C30.0794 16.5003 30.1147 16.4914 30.1499 16.4736C28.5894 14.5149 25.8827 11.2564 22.8146 8.18492C21.1659 6.52896 19.1028 5.66538 16.9516 5.66538Z"
      fill="#6E3264"
    />
    <path
      d="M32.0013 21.7708C34.3872 21.7708 36.3214 19.8177 36.3214 17.4084C36.3214 14.999 34.3872 13.0459 32.0013 13.0459C29.6153 13.0459 27.6812 14.999 27.6812 17.4084C27.6812 19.8177 29.6153 21.7708 32.0013 21.7708Z"
      fill="#56BB6C"
    />
    <path
      d="M32.0014 22.6609C31.6046 22.6609 31.199 22.6164 30.7935 22.5184C27.9986 21.8507 26.2706 19.0107 26.9407 16.1884C27.2669 14.8263 28.0956 13.6689 29.277 12.9299C30.4585 12.191 31.8603 11.9684 33.2092 12.2978C34.5582 12.6272 35.7043 13.4641 36.4361 14.6571C37.1678 15.8501 37.3883 17.2657 37.062 18.6278C36.4978 21.0405 34.3554 22.6698 32.0014 22.6698V22.6609ZM31.9925 13.9271C31.3666 13.9271 30.7494 14.1051 30.194 14.4434C29.4093 14.9331 28.8627 15.6987 28.6511 16.5979C28.2102 18.4587 29.3564 20.3372 31.1991 20.7823C33.0417 21.2186 34.902 20.0701 35.3428 18.2094C35.5544 17.3102 35.4045 16.3754 34.9284 15.583C34.4435 14.7906 33.6853 14.2387 32.7948 14.025C32.5303 13.9627 32.257 13.9271 31.9925 13.9271Z"
      fill="#6E3264"
    />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_202_2252"
        x1="21.492"
        x2="21.492"
        y1="21.1475"
        y2="59.2344"
      >
        <stop offset="0.495" stopColor="#F9F6F3" />
        <stop offset="1" stopColor="#FED7FF" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint1_linear_202_2252"
        x1="42.7675"
        x2="42.7675"
        y1="21.1475"
        y2="59.2344"
      >
        <stop offset="0.495" stopColor="#F9F6F3" />
        <stop offset="1" stopColor="#FED7FF" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint2_linear_202_2252"
        x1="42.1528"
        x2="42.1528"
        y1="7.45508"
        y2="21.9844"
      >
        <stop offset="0.4" stopColor="#A0DCFF" />
        <stop offset="1" stopColor="#56BB6C" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint3_linear_202_2252"
        x1="21.5239"
        x2="21.5239"
        y1="7.8916"
        y2="22.4209"
      >
        <stop offset="0.32" stopColor="#A0DCFF" />
        <stop offset="1" stopColor="#56BB6C" />
      </linearGradient>
    </defs>
  </svg>
)

export default SurpriseGift
